import React from 'react'
import PropTypes from 'prop-types'
// import Content from './Content'

const ConsultantPageTemplate = ({ title, notice, consultants, content, contentComponent }) => {
  // const PageContent = contentComponent || Content

  // function Notice({notice}) {
  //   if(notice.title !== null) {
  //     return (
  //       <article className="message">
  //         <div className="message-header">
  //           {notice.title}
  //         </div>
  //         <div className="message-body">
  //           <p>{notice.message}</p>
  //         </div>
  //       </article>
  //     )
  //   }
  //   else {
  //     return (
  //       < ></ >
  //     )
  //   }
  // }

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
              <h2 className="is-size-3">District Consultants and Vendors</h2>
              <hr/>
              <div className="columns is-multiline">
                {consultants.map(consultant => {
                  function Website({consultant}) {
                    if(consultant.website !== null)
                    {
                      if(consultant.website.startsWith('mailto'))
                      {
                        return (< ><br/><a href={consultant.website}>Email</a></ >)
                      }
                      else
                      {
                        return (< ><br/><a href={consultant.website}>Website</a></ >)
                      }
                    }
                    else { return (< ></ >)}
                  }
                  return (
                    <div key={consultant.company} className="column is-4">
                      <div className="card">
                        <div className="card-header">
                          <div className="card-header-title">
                            {consultant.role}
                          </div>
                        </div>
                        <div className="card-content">
                          <p>{consultant.company}<br/>
                          {consultant.address1}<br/>
                          {consultant.address2}
                          <Website consultant={consultant} />
                        </p>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
          </div>
        </div>
      </div>
    </section>
  )
}

ConsultantPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default ConsultantPageTemplate
